@import "../../../../styles/lib/helpers";
@import "../../../../styles/lib/variables";

//MAIN STYLES

.container {
  background: $shoppingBeige;
  box-shadow: none;
  color: $black;
  margin: 3.5rem -1rem 2rem;
  padding: 2rem 1rem;

  @include breakpoint('sm') {
    margin: 3.5rem 0 2rem;
    padding: 2rem;
  }

  &::before {
    background-image: url('./newsletter_zigzag.png');
    background-position: center;
    background-repeat: repeat-x;
    background-size: auto 40px;
    content: "";
    display: block;
    height: 40px;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: -50px;

    @include breakpoint('sm') {
      margin-left: -2rem;
      margin-right: -2rem;
    }
  }

  a {
    color: $black;
  }

  h2 {
    font-size: $text-4 !important;
  }

  &:hover {
    .holidayIcon {
      background-image: url('./giftsHover.svg');
    }
  }

}

.contentWrapper {
  max-width: 100%;
}

.titleWrapper {
  display: block;
}

.textWrapper {
  display: block;
}

.logo {
  display: none;
}

.description {
  padding-top: 8px;

  @include breakpoint('md') {
    padding-right: 124px;
  }
}

// .eyesImg {
//   height: 60px;
//   margin-bottom: $space-1;
//   width: auto;
//   flex: 0 0 auto;
//   margin-right: 1.25rem;
// }

.holidayIcon {
  background-image: url('./gifts.svg');
  background-size: contain;
  background-repeat: no-repeat;
  height: 80px;
  min-width: 115px;
  margin-bottom: $space-1;
  margin-right: 1.25rem;
}


.container:hover .eyesImg {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);

  transform: scaleX(-1);
}

.container:active .eyesImg {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);

  transform: scaleX(-1);
}

.container:focus .eyesImg {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);

  transform: scaleX(-1);
}

.form {
  button {
    background-color: $shoppingNavy;
    border: none;
    flex-grow: 1;
    height: 42px;
    margin: 0 0 $space-1;
    border-radius: 3px;
    color: $white;
    cursor: pointer;
    display: block;
    font-family: $proxima;
    font-size: 1rem;
    font-weight: $bold;
    box-sizing: border-box;

    @media (min-width: 768px) {
      margin: 1px 0 0 $space-05;
    }

    /* For mobile only: */
    @media (max-width: 767px) {
      width: 100%;
    }

    &:hover {
      color: $shoppingNavy;
      background-color: $shoppingGold;
    }
  }


  input[id^="newsletter-email-signup"] {
    background: $white;
    border-radius: 4px;
    border: 1px solid $shoppingNavy;
    box-sizing: border-box;
    flex-basis: 100%;
    flex-grow: 12;
    font-family: inherit;
    margin: 1px 1px 9px;

    @media (min-width: 768px) {
      flex-basis: 0;
    }

    &::placeholder {
      color: transparent;
    }

    /* - Internet Explorer 10–11 - important in required */
    &:-ms-input-placeholder {
      color: transparent !important;
    }

    &:not(:placeholder-shown)+label {
      transform: translate(-0.75rem, -80%);
      font-size: 14px;
      color: inherit;
    }

    &:focus {
      box-sizing: border-box;
      border: 2px solid $shoppingNavy;
      box-shadow: 0px 0px 4px rgba(140, 162, 233, 0.7);
      outline: none;
      margin: 0 0 8px;
    }
  }

  p[class*='newsletter-form_error'] {
    font-weight: 400;
    font-size: 16px;
    color: #E74A1C;
    padding: .5rem 0;
  }

  div[class*='recaptcha-branding_privacyPolicyBox'] {
    a:hover {
      color: #0F65F0;
    }

    @include breakpoint('md') {
      padding-right: 124px;
    }
  }
}

// SUCCESS STYLES

.success {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  height: 378px;
  padding-top: 64px;
  outline: none;
  text-align: center;

  .star {
    position: absolute;
    width: 40px;
    animation-name: spin;
    animation-duration: 1500ms;
    animation-timing-function: ease;
    transform: rotate(360);
    transform-origin: center;
    transform-box: fill-box;
  }

  .star1 {
    bottom: -10%;
    left: 15%;

    @include breakpoint('md') {
      bottom: 0%;
      left: 5%;
    }
  }

  .star2 {
    bottom: 50%;
    left: 90%;

    @include breakpoint('md') {
      bottom: 60%;
      left: 85%;
    }
  }

  .star3 {
    bottom: 75%;
    left: 0%;

    @include breakpoint('md') {
      bottom: 70%;
      left: 15%;
    }
  }

  .star4 {
    display: none;
  }

  p[class*='newsletter-signup-success_title'] {
    text-transform: capitalize;
  }
}

.title {
  font-size: $text-4;
  font-weight: $bold;
  margin: $space-2 0 $space-1;
  animation-name: fadeIn;
  animation-duration: 1000ms;
  animation-timing-function: ease-out;
  text-align: center;
}

.text {
  font-size: $text-5;
  margin-bottom: $space-2;
  width: 96%;
  animation-name: slideInFromBottom;
  text-align: center;
  animation-name: fadeIn;
  animation-duration: 1000ms;
  animation-timing-function: ease-out;
}

.thankYouImg {
  width: 150px;
  height: auto;
  animation-name: slideInFromTop;
  animation-duration: 1500ms;
  margin-bottom: $space-3;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-30%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

.truckWrapper {
  width: 100%;
}

.truckImg {
  position: relative;
  width: 100px;
  height: 50px;
}

 @include breakpoint('md') {
  .truckWrapper {
    width: auto;
    overflow: visible;
    position: relative;
    display: inline-block;
  }

  .truckImg {
    margin-right: 24px;
    width: 120px;
    height: 60px;
    animation: none;
  }
}

@include breakpoint('lg') {
  .truckImg {
    margin-left: 10px;
  }
}