@import '../../../../styles/lib/helpers';
.desktopStickyAdWrapper {
  display: none;
  @include breakpoint('lg') {
    display: block;
  }
}

.sticky {
  position: fixed;
  width: 100%;
  top: 64px;
  left: 0;
  z-index: 500;
  background: rgba(0, 0, 0, 0.15);
  opacity: 0;
  transition-property: opacity;
  transition-duration: 2s;
  transition-delay: 3s;
  :global {
    .Ad--awareness-bp {
      background-color: transparent;
      .ad-awareness.ad--rendered.ad-flexible--programmatic {
        background-color: transparent;
      }
    }
  }
  &.breakingBar {
    margin-top: 0px;
  }
}

.placeholderEnabled {
  display: none;
  @include breakpoint('lg') {
    display: block;
  }
}

.breakingBar {
  margin-top: 24px;
}
