@import "../../../../styles/lib/helpers";

.title {
  @include font-size('text-3');
  font-weight: $bold;
  margin-bottom: $space-1;
}

.text {
  @include font-size('text-5');
  margin-bottom: $space-2;
}
