@import "../../../../styles/lib/mixins";
@import "../../../../styles/lib/variables";

.form {
  margin-top: $space-2;
}

.fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.legend {
  @include screen-reader-only;
}

.submitBox {
  display: flex;
  margin-top: $space-2;
  position: relative;
  flex-wrap: wrap;
}

.label {
  position: absolute;
  color: $gray-7;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: .5rem .75rem;
  border: 1px solid transparent;
  transition: transform 150ms ease-out, color 150ms ease-out;

  @media all and (-ms-high-contrast:none) {
    transform: translate(-.75rem, -80%);
    font-size: 14px;
    color: inherit;
    left: 0
  }
}

.input {
  flex-grow: 3;
  font-family: inherit;
  background: #fff;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: .5rem .75rem;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
  margin: 0 0 $space-1;
  flex-basis: 100%;

  @include breakpoint("md") {
    flex-basis: 0;
  }

  &::placeholder {
    color: transparent;
  }

  /* - Internet Explorer 10–11 - important in required */
  &:-ms-input-placeholder {
    color: transparent !important;
  }

  &:not(:placeholder-shown) + label {
    transform: translate(-.75rem, -80%);
    font-size: 14px;
    color: inherit;
  }
}

.button {
  -webkit-appearance: none;
  background: none;
  border-radius: 3px;
  border: 1px solid #fff;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: block;
  flex-grow: 1;
  font-family: $proxima;
  font-size: 1rem;
  font-weight: $bold;
  margin-bottom: $space-1;
  margin-left: 0;
  min-width: 110px;
  padding: .5rem 1rem;
  transition: background-color .1s ease 0s;
  z-index: 1;

  @include breakpoint("md") {
    margin-left: $space-05;
  }
}

.error {
  font-weight: $bold;
}

.success {
  // stylelint-disable-next-line declaration-no-important
  font-weight: $bold !important;
  margin-bottom: $space-1;
}

.loading {
  opacity: .6;
}

.checkmarkIcon {
  height: 1rem;
  fill: $white;
  width: 1rem;
}

.spinner {
  @keyframes loader1 {
    0% { transform: rotate(20deg); }
    100% { transform: rotate(380deg); }
  }
  @keyframes loader2 {
    0% { transform: scale(0); }
    100% { transform: scale(1); }
  }

  position: absolute;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
  z-index: 9999;
  animation: loader1 1s infinite linear;

  span {
    @include screen-reader-only;
  }

  &:before,
  &:after {
    position: absolute;
    content: " ";
    width: 80%;
    height: 80%;
    background-color: #fff;
    top: 5%;
    left: 0;
    border-radius: 50%;
  }

  &:before {
    left: -6%;
    animation: loader2 1s infinite alternate ease-in-out;
    transform-origin: 10% 50%;
  }

  &:after {
    left: auto;
    right: -6%;
    animation: loader2 1s 1s infinite alternate ease-in-out;
    transform: scale(0);
    transform-origin: 90% 50%;
  }
}
