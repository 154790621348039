@import "../../../../../styles/lib/variables";
@import "../../../../../styles/lib/helpers";

$promo-red: #E74A4A;

.wrapper {
  &::before {
    content: " ";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;

    background-color: #F8E4CA;
    background-image: url("../img/pets-newsletter-pattern.png");
    opacity: .5;
    z-index: -1;
  }
}

.container {
  padding: $space-2;
  padding-bottom: $space-4;
}

.copy {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    color: $promo-red;
  }
}

.form {
  button {
    background-color: $promo-red;
    border: 0;

    @include breakpoint ("md") {
      margin-left: $space-1;
    }

    &:hover {
      background-color: #fff;
      color: #222222;

      svg {
        fill: #222222;
      }
    }
  }

  p[role="alert"] {
    font-weight: $regular;
    font-size: $text-6;
  }

  p[class^="success"] {
    color: $promo-red;
  }

  p[class^="error"] {
    color: #000;
  }
}

@include breakpoint ("md") {
  .container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
  }

  .copy {
    margin-right: 30px;
    width: 50%;
  }

  .form {
    min-width: 260px;
    width: 50%;

    div[class^="submitBox"] {
      flex-direction: column;
    }

    button {
      margin-left: 0;
    }
  }
}

@include breakpoint ("lg") {
  .container {
    max-width: 1000px;
    padding: 1rem 0 !important;
  }

  .form {
    div[class^="submitBox"] {
      flex-direction: row;
      margin-right: 30px; // leave room for x-icon
    }

    input[id^="newsletter-email-signup"] {
      flex-grow: 5;
    }

    button {
      margin-left: $space-1;
    }
  }
}
