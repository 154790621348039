@import "../../../../../styles/lib/variables";
@import "../../../../../styles/lib/helpers";

.container {
  padding: $space-2;
  padding-bottom: $space-4;
}

.wrapper {
  div[class*='newsletter-signup-shopping_container'] {
    margin: 1.5rem 0 0;
  }
  div[class*='newsletter-signup-shopping_description'] {
    font-size: 17px;
    padding-right: 0;
  }
  div[class*='newsletter-signup-shopping_success'] {
    padding: 80px 0;
    height: auto;
  }
  p[class*='newsletter-signup-success_title'] {
    padding-bottom: 8px;
  }
  svg[class*='newsletter-signup-shopping_thankYouImg'] {
    display: none;
  }
  svg[class*='newsletter-signup-shopping_star1'] {
    left: 10%;
    bottom: 70%;
  }
  svg[class*='newsletter-signup-shopping_star2'] {
    left: 80%;
    bottom: 60%;
  }
  svg[class*='newsletter-signup-shopping_star3'] {
    left: 30%;
    bottom: -15%;
  }

  @include breakpoint ("md") {
    p[class*='newsletter-signup-success_title'] {
      padding-bottom: 4px;
    }
    div[class*='newsletter-signup-shopping_contentWrapper'] {
      max-width: 1000px;
      margin: 0 auto;
      display: flex;
    }
    div[class*='newsletter-signup-shopping_titleWrapper'] {
      display: flex;
      max-width: 545px;
      padding-right: 40px;
      align-items: center;
    }
    div[class*='newsletter-signup-shopping_form'] {
      margin-top: 0;
    }
    div[class*='newsletter-form_submitBox'] {
      margin-top: 0;
    }
    div[class*='newsletter-signup-shopping_success'] {
      padding: 20px 0 0;
    }
    svg[class*='newsletter-signup-shopping_star1'] {
      left: 70%;
      bottom: -50%;
    }
    svg[class*='newsletter-signup-shopping_star2'] {
      left: 90%;
      bottom: -10%;
    }
    svg[class*='newsletter-signup-shopping_star3'] {
      left: 23%;
      bottom: -10%;
    }
    svg[class*='newsletter-signup-shopping_star4'] {
      display: block;
      left: 5%;
      bottom: -49%;
    }
  }
  @media screen and (min-width: 71rem) {
    svg[class*='newsletter-signup-shopping_star2'] {
      left: 100%;
    }
    svg[class*='newsletter-signup-shopping_star4'] {
      left: -5%;
    }
  }

  div[class*='recaptcha-branding_privacyPolicyBox'] {
    font-size: .75rem;
    line-height: 1.2;
    color: #222;

    a {
      color: #222;
    }
  }
}

.form {
  button {
    background-color: $blue;

    @include breakpoint ("md") {
      margin-left: $space-1;
    }

    &:hover {
      background-color: darken($blue, 20%);
    }
  }

  p[role="alert"] {
    font-weight: $regular;
    font-size: $text-6;
  }

  p[class^="success"] {
    color: $green-5;
  }

  p[class^="error"] {
    color: $red-5;
  }


  div[class*='recaptcha-branding_privacyPolicyBox'] {
    color: #222;

    a {
      color: #222;
    }
  }
}

@include breakpoint ("md") {
  .container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;

    &::before {
      background-image: url('../img/three_gifts.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      margin-right: 30px;
      opacity: 1;
      position: relative;
      min-width: 140px;
    }
  }

  .copy {
    margin-right: 30px;
    width: 50%;
  }

  .form {
    min-width: 260px;
    width: 50%;

    div[class^="submitBox"] {
      flex-direction: column;
    }

    button {
      margin-left: 0;
    }
  }
}

@include breakpoint ("lg") {
  .container {
    max-width: 1000px;
    padding: 1rem 0 !important;
  }

  .form {
    div[class^="submitBox"] {
      flex-direction: row;
      margin-right: 30px; // leave room for x-icon
    }

    input[id^="newsletter-email-signup"] {
      flex-grow: 5;
    }

    button {
      margin-left: $space-1;
    }
  }
}
