@import "../../../../styles/lib/variables";

.privacyPolicyBox {
  width: 100%;

  .culturePopup {
    color: $blue;
  }

  a {
    color: #fff;
  }
}
