@import "../../../styles/lib/variables";

.promoPopup {
  background-color: transparent;
  display: block;
  user-select: none;
  pointer-events: none;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: max-height 500ms ease;
  z-index: 400;

  &:focus {
    outline: none;
  }
}

.visible {
  user-select: inherit;
  pointer-events: inherit;
  max-height: 100%;
}

.closeButton {
  cursor: pointer;
  appearance: none;
  border: none;
  background: none;
  color: $white;
  opacity: .75;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 30px;
  z-index: 10;

  &:hover,
  &:focus {
    opacity: 1;
  }

  svg {
    height: 25px;
    width: 25px;
  }
}
