@import "../../styles/lib/helpers";

.pageFooter {
  background-color: $news-c-gray-warm;
  .container {
  max-width: 75rem;
  margin: 0 auto;
  }
}

.footerTop {
  @include breakpoint("md") {
    display: flex;
    justify-content: space-between;
    margin-bottom: $space-4;
  }
}

.footerInternal {
  @include breakpoint("lg") {
    width: percentage(5/8);
  }
}

.footerExternal {
  display: flex;
  justify-content: space-between;
  @include breakpoint("sm") {
    width: percentage(2/3);
  }

  @include breakpoint("md") {
    width: percentage(2/5);
  }

  @include breakpoint("lg") {
    width: percentage(3/8);
  }

  .column {
    width: calc(50% - 0.5rem);
    @include breakpoint("md") {
      width: auto;
    }
  }

  .list {
    margin-bottom: 0;
  }
}

.list {
  font-size: $news-text-5;
  margin-bottom: $space-3;
  .listItem {
    margin-bottom: $space-2;
  }
  .listLink {
    color: $black;
    text-decoration: none;
    &:hover {
      color: $news-c-gray-dark;
      @include text-underline($news-c-gray-dark);
    }
  }
}

.listSections {
  column-count: 2;
  column-gap: $space-2;
  .listItem {
    display: flex;
  }
  @include breakpoint("sm") {
    column-count: 3;
    .listLink {
      word-spacing: 0px;
    }
  }
  @include breakpoint("md") {
    &:after {
      content: '';
      display: block;
      height: 20px;
    }
  }
  @include breakpoint("lg") {
    column-count: 4;
  }
}

.listFollow {
  .listLink {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    svg {
      display: block;
      height: 1.5em;
      width: 1.5em;
      margin-right: $space-1;
    }
  }
}

.heading {
  @include font-size('text-5');
  margin-bottom: $space-2;
}

.footerBottom {
  margin-bottom: $space-2;
  @include breakpoint("md") {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.ccpa {
  font-size: $text-7;
  line-height: 1.167;
  color: $news-c-gray-darken;
  margin-bottom: $space-3;
  p {
    margin-bottom: $space-2;
  }
  .button {
    display: inline-block;
    font-size: $text-6;
    font-family: $basier;
    line-height: 1.25rem;
    padding: .3125rem .625rem;
    color: $white;
    background-color: $black;
    border: 1px solid $black;
    border-radius: 4px;
    transition: background-color .1s ease 0s,  border-color .1s ease 0s;
    cursor: pointer;
    &:hover {
      background-color: $news-c-gray-darken;
      border-color: $news-c-gray-darken;
    }
  }
  @include breakpoint("sm") {
    width: percentage(2/3);
  }

  @include breakpoint("md") {
    width: percentage(2/5);
    flex-direction: row-reverse;
  }

  @include breakpoint("lg") {
    width: percentage(3/8);
  }
}

.listBrand {
  font-size: $text-6;
  .listItem {
    margin-bottom: $space-1;
  }
  .listLink {
    color: #3d3c3c;
    &:hover {
      color: $news-c-gray-dark;
      text-decoration: none;
      .buzzfeedNewsLogo {
        opacity: 0.7;
      }
      .buzzfeedLogo {
        path {
          fill: $news-c-gray-dark;
        }
      }
    }
  }
  .buzzfeedNewsLogo {
    width: 192px;
    height: auto;
  }
  .buzzfeedLogo {
    position: relative;
    top: 1px;
    width: 72px;
    height: auto;
  }
}

.footerList {
  list-style: none;
  margin: 1rem 0 0 0;
  padding: 0 0 1.5rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  max-width: 779px;

  @include breakpoint("sm") {
    padding-bottom: 0;
  }

  .footerListItem {
    margin-bottom: .75rem;
    text-align: center;
    font-size: 12px;
    line-height: 15px;
    padding: 3px;
  }

  .footerLink {
    text-decoration: none;
    color: $black;

    &:hover {
      color: $news-c-gray-dark;
      text-decoration: underline;
    }
  }
}
