@import '../../../../styles/lib/helpers';

.placeholderAd {
    min-height: 66px;
    @include breakpoint('md') {
        min-height: 122px;
    }

    @include breakpoint('lg') {
        &.buzzfeed {
            min-height: 122px;
        }
        &.buzzfeed_news {
            min-height: 0px;
        }
    }
}
