.contentWrapper {
  background-color: #B4B6D6;
  min-height: 400px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 34px 16px 68px;

  &:hover {
    cursor: pointer;
  }

  @media(min-width: 600px) {
    flex-direction: row;
    min-height: 167px;
    padding: 10px;
  }
}

.background {
  position: absolute;
  opacity: 0.3;
  width: 100%;
  object-fit: cover;
  height: 400px;

  @media(min-width: 600px) {
    height: auto;
  }
}

.link {
  text-decoration: none;
  color: #00033F;
}

.character {
  position: absolute;
  top: 10px;
  left: 100px;
  transform: translateX(-100%) rotate(-6deg);
  height: 107px;
  z-index: 0;

  @media(min-width: 600px) {
    margin-right: 40px;
    height: 180px;
    top: -25px;
  }
}

.hat {
  position: absolute;
  top: -5px;
  left: 12px;
  width: 75px;
  transform: scaleX(-1) rotate(-8deg);
  z-index: 2;

  @media(min-width: 600px) {
    margin-right: 40px;
    width: 130px;
    top: -50px;
    left: -50px;
  }
}

.messageWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 0 16px;
  padding-left: 115px;

  @media(min-width: 600px) {
    margin: 0 16px 32px;
  }
}

.message {
  position: relative;
  display: flex;
  margin-right: auto;
  align-items: center;
  background-color: #00033F;
  font-size: 14px;
  display: flex;
  padding: 8px 14px;
  border-radius: 50px 100px 100px 0px;
  color: white;
  margin-bottom: 2px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);

  @media(min-width: 600px) {
    margin-right: 0;
  }
}

.input {
  display: flex;
  align-items: center;
  font-size: 14px;
  min-height: 36px;
  padding: 8px 10px 8px 14px;
  border-radius: 100px;
  background: #FFF;
  color: #00033F;
  margin: 16px 16px 0;
  z-index: 0;
  width: 100%;

  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);

  img {
    height: 20px;
    width: 20px;
    margin-left: auto;
  }

  @media(min-width: 600px) {
    margin: 8px 0 0;
    font-size: 16px;
    min-width: 250px;
  }

  @media(min-width: 700px) {
    margin: 8px 0 0;
    font-size: 16px;
    min-width: 340px;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  max-width: 384px;
  z-index: 1;
  margin: 0 10px 32px;

  b {
    font-size: 24px;
    margin-bottom: 4px;
  }

  span {
    font-size: 18px;
  }
}

.banner {
  position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  background-color: #00033F;
  padding: 0 18px;
  width: 100%;
  color: white;
  font-weight: 900;
  font-size: 14px;
  height: 32px;
  overflow: hidden;
  z-index: 2;
  gap: 16px;

  .marquee {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    gap: 16px;
    min-width: 100%;
    animation: marquee 20s linear infinite;

    img {
      margin-top: 2px;
    }
  }
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - 16px));
  }
}