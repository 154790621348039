//
// Mixins
// --------------------------------------------------

// Generate Breakpoint Prefixes
// -------------------------
// this mixin adds breakpoint prefixes
// from the list of breakpoints to the
// beginning of a block of css selectors

// Include Breakpoint Media Queries
// -------------------------
// returns the apropriate media query
// for the given breakpoint name
@mixin breakpoint ($breakpoint) {
    @if (map-has-key($breakpoints, $breakpoint) != true) {
      @error "Breakpoint media query mixin requires a valid breakpoint";
    }
    @else if(map-get($breakpoints, $breakpoint)) == null {
      @content;
    }
    @else {
      @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
        @content;
      }
    }
}

@mixin generate-breakpoint-prefixes {
    // generate prefixed classes
  @each $breakpoint-name, $breakpoint-value in $breakpoints {
    $breakpoint-prefix: "#{$breakpoint-name}-";

    @include breakpoint($breakpoint-name) {
      // Columns
      .#{$breakpoint-prefix} {
        @content;
      }
    }
  }
}

// hide from humans, not screen readers!
@mixin screen-reader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px; margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// include an svg icon as a background image using variables
@function svg-background($svg-variable) {
  @return url("data:image/svg+xml,#{$svg-variable}");
}


@mixin font-size($size) {
  font-size: map-get($font-sizes, $size);
  line-height: map-get($line-heights, $size);

  // scope the selector to `<main>` so we don't impact the header
  html[lang="ja"] main & {
    font-size: map-get($font-sizes-ja, $size);
    line-height: map-get($line-heights, $size);
  }
}

@mixin jp-font-size($size) {
  html[lang="ja"] main & {
    font-size: map-get($font-sizes-ja, $size);
    line-height: map-get($line-heights, $size);
  }
}

@mixin text-underline($color) {
  text-decoration: underline; // fallback for some browsers
  text-decoration: underline solid $color;
}
