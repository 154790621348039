//
// Variables
// --------------------------------------------------

// Config
// -------------------------

$breakpoints: (
  xs: null,
  sm: 40rem,
  md: 52rem,
  lg: 64rem,
  xl: 89rem,
  stickyHeader: 500px, // for sticky header - pulled from bf_bpage_ui
);

// Fonts
// -------------------------

$proxima: "Proxima Nova", Helvetica, Arial, sans-serif !default;
$pensum: "Pensum", "Times New Roman", times, serif;
$basier: "Basier", Helvetica, Arial, sans-serif;

// Borders
// -------------------------

$border: 1px solid rgba(0, 0, 0, .2) !default;
$border-lighter: 1px solid rgba(0, 0, 0, .1) !default;
$border-radius: 3px !default;


// Spacing
// -------------------------

$space-05: .25rem !default; // 4px
$space-1: .5rem !default; // 8px
$space-2: 1rem !default; // 16px
$space-3: 1.5rem !default; // 24px
$space-4: 2rem !default; // 32px
$space-5: 3rem !default; // 48px
$space-6: 4.5rem !default; // 72px

// Z-index
// -------------------------

$z1: 100 !default;
$z2: 200 !default;
$z3: 300 !default;
$z4: 400 !default;

// Neutral Colors
// -------------------------

$black: #222222 !default;
$gray-7: #757575 !default;
$gray-5: #aaaaaa !default;
$gray-3: #d7d7d7 !default;
$gray-1: #f4f4f4 !default;
$white: #ffffff !default;

// Utility Colors
// -------------------------

$purple: #6645dd !default;
$purple-lightest: #E2E0FF !default;
$pink: #e40c78 !default;
$blue: #0f65ef !default;
$teal: #007c7c !default;
$orange: #f47f16 !default;
$green-5: #68af15 !default;
$green-1: #e1efd0 !default;
$red-5: #ee3322 !default;
$red-1: #fdebe9 !default;
$yellow-5: #ffee00 !default;
$yellow-1: #fffab6 !default;

// A11y
// -------------------------
$a11y-blue-focused: #7a9ff3 !default;

// Social Colors
// -------------------------

$facebook: #3b5998 !default;
$twitter: #55acee !default;
$google: #dd4b39 !default;
$linkedin: #2867B2 !default;
$pinterest: #e60019 !default;
$tumblr: #001935 !default;
$youtube: #FF0000 !default;
$instagram: #000000 !default;
$buzzfeed: $red-5 !default;
$snapchat: #fffc00 !default;

// Gradients
//---------------------------

$gradient-pink-purple: linear-gradient(to right, $pink, $purple);
$gradient-red-pink: linear-gradient(285deg, $pink, $red-5);
$gradient-pink-red: linear-gradient(to left, $red-5, $pink);

// Community promo banner
// -------------------------

$promo-banner-button-color: #1A3E81 !default;
$promo-banner-bg-color: #E8EBFC !important;


// Typography Sizes and Weights
// ------------------------------

$base-font-size: 16px !default;

$text-1: 2.5rem !default; // 40px
$text-2: 2rem !default; // 32px
$text-3: 1.625rem !default; // 26px
$text-4: 1.375rem !default; // 22px
$text-5: 1.125rem !default; // 18px
$text-6: .875rem !default; // 14px
$text-7: .75rem !default; // 12px

$news-text-2: 2.25rem; // 36px
$news-text-5: 1rem; // 16px
$news-text-7: .813rem; // 13px
$regular: 400 !default;
$bold: 700 !default;

$font-sizes: (
  text-1: $text-1,
  text-2: $text-2,
  news-text-2: $news-text-2,
  text-3: $text-3,
  text-4: $text-4,
  text-5: $text-5,
  text-6: $text-6,
  text-7: $text-7,
);

$line-heights: (
  text-1: 1.025,
  text-2: 1.28,
  news-text-2: 1.139,
  text-3: 1.2,
  text-4: 1.27,
  text-5: 1.2,
  text-6: 1.21,
  text-7: 1.25,
);

// Variable overrides for Japanese-language posts
// ------------------------------
$font-sizes-ja: (
  text-1: 1.5rem, // 24px
  text-2: 1.5rem, // 24px
  text-3: 1.5rem,// 24px
  text-4: 1.25rem, // 20px
  text-5: 1rem, // 16px
  text-6: .875rem, // 14px
  text-7: .75rem, // 12px
);

$line-heights-ja: (
  text-1: 1.3,
  text-2: 1.3,
  text-3: 1.3,
  text-4: 1.3,
  text-5: 1.5,
  text-6: 1.21,
  text-7: 1.25,
);

// News Colors
// ------------------------------
$news-c-black: #222;
$news-c-gray-dark: #717171;
$news-c-gray-darken: #535454;
$news-c-gray: #cdcdcd;
$news-c-gray-warm: #f1f1ec;
$news-c-gray-light: #f4f4f4;
$news-c-white: #fff;
$news-c-red: #de2f23;
$news-c-red-2: #da332a;
$news-c-yellow: #ffcb04;
$news-c-placeholder: rgba(0,0,0,.05);

// News global variables
// ------------------------------
$news-w-medium: 600;

// Shopping variables
$shoppingGrape: #323254 !default;
$shoppingGold: #e89818 !default;
$shoppingBeige: #ffe3d4 !default;
$shoppingOffWhite: #faf8f8 !default;
$shoppingRed: #e74a1c !default;
$shoppingNavy: #323254 !default;

//Shopping Interactive Colors
$shopping-blue-pressed: #05204D;

// Topics bpage update
$accentPink: #eb5369;

// discussion
$color-aqua: #b1ecfd;
$color-feed-yellow: #f0fa81;
$color-feed-purple: #5246f5;
$color-feed-pink: #eb5369;

// Mobile Sizes
$mobile-width: 639px;
