@import "../../../../styles/lib/helpers";

.title {
  @include font-size('text-5');
  margin: 0 0 $space-2;
  font-weight: $bold;
  width: 100%;

  @include breakpoint("md") {
    @include font-size('text-4');
  }

  @include breakpoint("lg") {
    width: 75%;
  }
}
